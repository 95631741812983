@media all and (min-width: 480px) {
    .Login {
      padding: 60px 0;
      font-size: 1.3rem;
    }
  
    .Login form {
      margin: 0 auto;
      max-width: 400px;
    }

    .Login form a {
      margin-top: 15px;
      display: block;
    }

    .Logo {
      text-align: center;
      margin-bottom: 15px;
    }

    .Logo img {
      max-width: 400px;
    }    

    #Login_Grid {
      max-width: 400px;
    }
  }