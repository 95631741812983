/*body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}*/

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ReactVirtualized__Table {
  display: inline-block;
  border: 2px solid rgba(0, 0, 0, 0.1);
}

.ReactVirtualized__Table__headerColumn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0;
}

.ReactVirtualized__Table__headerTruncatedText {
  flex: auto;
}

.DragHandle {
  flex: 0 0 16px;
  z-index: 2;
  cursor: col-resize;
  color: #0085ff;
}
.DragHandle:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.DragHandleActive,
.DragHandleActive:hover {
  color: #0b6fcc;
  z-index: 3;
}

.DragHandleIcon {
  flex: 0 0 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}