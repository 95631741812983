@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&display=swap');

.App {
  margin-top: 0px;
}

.App .navbar {
  background-color: rgb(45, 48, 145);

}
/* dscxxxxxxxxxxxxxxxxxxxxxxxxxxxxx */
.App .navbar-brand {
  font-weight: bold;
  color: white;
}

.App #menu-button {
  border: none;
  color: white;
  padding: 0;
  background-color: rgb(45, 48, 145);
  height: 49px;
}

/*.App .user.glyphicon {
  padding: 15px 15px;
  padding-right: 5px;
  font-size: 20px;
  color: rgb(255, 204, 2);
  color: white;
}*/

.account-circle {
  width: 1.5em !important;
  height: 1.5em !important;
}

.menu-account {
  font-size: 1rem !important;
}

.logout.glyphicon {
  padding: 5px;
}