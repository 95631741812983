@media all and (min-width: 480px) {
    .Signup {
      padding: 60px 0;
    }
  
    .Signup form {
      margin: 0 auto;
      max-width: 400px;
    }

    .Signup form a {
      margin-top: 15px;
      display: block;
      font-size: 14px;
      text-align: center;
    }

    .Signup form span.help-block {
      font-size: 14px;
      padding-bottom: 10px;
      color: #999;
    }

    .Logo {
      text-align: center;
      margin-bottom: 15px;
    }

    .Logo img {
      max-width: 400px;
    }  
  }
  